<template>
  <page-header-wrapper style="margin: unset;">
    <a-form-model ref='form' :model='form' layout='inline'>
      <a-form-model-item prop='memberName'>
        <a-input v-model='form.memberName' style="width: 300px" placeholder="兑换人" />
      </a-form-model-item>
      <a-form-model-item prop='memberMobile'>
        <a-input v-model='form.memberMobile' style="width: 300px" placeholder="手机号" />
      </a-form-model-item>
      <a-form-model-item prop='start'>
        <a-range-picker
          v-model="dateArr"
          :format="[formatType, formatType]"
          :show-time="{
            hideDisabledOptions: true,
          }"
          :disabled-date="disabledDate"
          @change="dateChange"
        />
      </a-form-model-item>
      <a-form-model-item prop='areaName'>
        <a-input v-model='form.areaName' style="width: 300px" placeholder="分公司简称" />
      </a-form-model-item>
      <a-form-model-item prop='status'>
        <a-select v-model="form.status" placeholder="状态" style="width: 250px">
          <a-select-option  :value="undefined">
            全部
          </a-select-option>
          <a-select-option  value="delivering">
            待发货
          </a-select-option>
          <a-select-option  value="receiving">
            已发货
          </a-select-option>
          <a-select-option  value="finished">
            已完成
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item>
        <a-button type='primary' @click='handleSubmit'>
          查询
        </a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click='handleReset'>
          重置
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <div>
      <div class='table-operator' style="margin-top: 20px">
        <a-button type='primary' icon='reload' @click='$refs.exchangeTable.refresh(true)'>刷新</a-button>
        <a-button type='primary' icon='delete' @click='handleDeleteAll'>删除</a-button>
        <a-button type='primary' icon='table' @click='exportToExcel1'>导出</a-button>
        <a-button type='primary' icon='plus' @click='initialExchangeWin'>兑换录单</a-button>
      </div>

      <s-table id="exchangeTable" ref='exchangeTable' rowKey='id' size='default' :columns='exchangeColumns' :scroll='{ x: 1500}' :data='loadData' :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
        <span slot='status' slot-scope='text, record'>
          <template>
              <span v-if="text=='delivering' && record.bussType=='deliver'">待发货</span>
              <span v-if="text=='delivering' && record.bussType=='draw'">待提货</span>

              <span v-if="text=='receiving' && record.bussType=='deliver'">已发货</span>
              <span v-if="text=='receiving' && record.bussType=='draw'">已提货</span>

              <span v-if="text=='finished'">已完成</span>
          </template>
        </span>

        <span slot='orderProductionDesc' slot-scope='text, record'>
          <template  v-for='product in productListStr(text)'>
<!--            <div>{{product}}</div>-->
            {{product}}
          </template>
        </span>

        <span slot='sendDesc' slot-scope='text, record'>
          <template  v-for='info in sendInfoDesc(record)'>
              {{info}}
          </template>
        </span>

        <span slot='action' slot-scope='text, record'>
          <template>
            <a-space>
                  <a href='#' @click='showChuHuo(record)'> 打印出库单 </a>
            </a-space>
             <a-divider type='vertical' />
            <a-space>
              <a v-if='record.bussType=="deliver" && (!record.address.logisticsProvider || !record.address.logisticsAmount || !record.address.logisticsNo) ' @click='initialDeliver1(record)'>
                物流金额
                  <a-divider type='vertical' />
              </a>
            </a-space>

            <a-space>
              <a-popconfirm v-if="record.status == 'delivering'" title='确定发货吗？' ok-text='确认' cancel-text='取消' @confirm='initialDeliver(record)'>
                <a href='#' v-if="record.bussType=='deliver'">确定发货</a>
                <a href='#' v-if="record.bussType=='draw'">确定提货</a>
              </a-popconfirm>
              <a-popconfirm title='确定删除吗？' ok-text='确认' cancel-text='取消' @confirm='handleDelete(record)'>
                <a href='#'>删除</a>
              </a-popconfirm>
            </a-space>
          </template>
        </span>
      </s-table>
    </div>

    <a-modal title='确认发货' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver.visible' @cancel='()=>this.deliver.visible=false'
             ok-text='确认' cancel-text='取消' @ok='handleDeliver' :confirmLoading='deliver.loading' :width="800">
      <a-form-model ref='form' :model='deliver.form' :rules='deliver.rules' :labelCol='{ span: 4 }' :wrapperCol='{ span: 20 }'>
        <a-form-model-item label='总需发货'>
          <s-table ref='table1' rowKey='id' size='default' :columns='columns1' :data='loadData1'>
            <span slot='stitle' slot-scope='text'>
              <template>
                  <Ellipsis :lines='2' tooltip :length='20'>{{ text }}</Ellipsis>
              </template>
            </span>
          </s-table>
        </a-form-model-item>
        <a-form-model-item label='订单号'>
          {{deliver.form.id}}
        </a-form-model-item>
        <template v-if="deliver.form.address">
          <a-form-model-item label='收货人'>
            {{deliver.form.address.name}}
          </a-form-model-item>
          <a-form-model-item label='联系电话'>
            {{deliver.form.address.mobile}}
          </a-form-model-item>
          <a-form-model-item label='详细地址'>
            {{ deliver.form.address.regionOneName }}
            {{ deliver.form.address.regionTwoName }}
            {{ deliver.form.address.regionThreeName }}
            {{ deliver.form.address.address }}
          </a-form-model-item>
        </template>
<!--        <a-form-model-item label='物流名称' prop='logisticsProvider'>-->
<!--          <a-select v-model="deliver.form.logisticsProvider" placeholder="请选择">-->
<!--            <a-select-option :value="item" v-for="(item, index) in expressList" :key="index">-->
<!--              {{item}}-->
<!--            </a-select-option>-->
<!--          </a-select>-->
<!--        </a-form-model-item>-->
<!--        <a-form-model-item label='物流单号' prop='logisticsNo'>-->
<!--          <a-input v-model='deliver.form.logisticsNo' />-->
<!--        </a-form-model-item>-->

      </a-form-model>
    </a-modal>

    <a-modal title='确认提货' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver2.visible' @cancel='()=>this.deliver2.visible=false'
             ok-text='确认' cancel-text='取消' @ok='handleConfirm' :confirmLoading='deliver2.loading' width="70%">
      <a-form-model ref='form2' :model='deliver2.form' :rules='deliver2.rules' :labelCol='{ span: 4 }' :wrapperCol='{ span: 20 }'>
        <a-card style="width: 90%;margin-left: auto;margin-right: auto">

          <a-descriptions title="订单购买明细" :column="3" bordered >
            <template v-for="(item, index) in deliver2.form.productionList" >
              <a-descriptions-item :key="index+'1'" label='商品名称'>{{item.title}}</a-descriptions-item>
              <a-descriptions-item :key="index+'2'" label='价格'>
                {{item.price}}积分
              </a-descriptions-item>
              <a-descriptions-item :key="index+'3'" label='数量'>{{item.quantity}}</a-descriptions-item>
            </template>
          </a-descriptions>

          <a-descriptions  :column="4" bordered layout="vertical">
            <a-descriptions-item  label='发货方式' >
              <a-radio-group name="radioGroup" v-model="deliver2.form.bussType" disabled>
                <a-radio value="draw">
                  自提现货
                </a-radio>
                <a-radio value="deliver">
                  物流发货
                </a-radio>
              </a-radio-group>
            </a-descriptions-item>
            <a-descriptions-item  label='订单号'>
              {{deliver2.form.id}}
            </a-descriptions-item>
            <a-descriptions-item  label='提货人'>
              {{deliver2.form.drawName}}
            </a-descriptions-item>
            <a-descriptions-item  label='提货人手机号'>
              {{deliver2.form.drawMobile}}
            </a-descriptions-item>
          </a-descriptions>

        </a-card>
      </a-form-model>
    </a-modal>

    <a-modal title='兑换录单' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver1.visible' @cancel='()=>this.deliver1.visible=false' width="30%"
             ok-text='确认' cancel-text='取消' @ok='handleDeliver1' :confirmLoading='deliver1.loading'>
      <a-form-model ref='form1' :model='deliver1.form' :rules='deliver1.rules' :labelCol='{ span: 5 }' :wrapperCol='{ span: 18 }'>
        <a-form-model-item label='兑换人手机号' prop='mobile' >
          <a-input v-model='deliver1.form.mobile' @change="onChangeMobile" />
        </a-form-model-item>

        <a-form-model-item label='当前兑换积分' prop='exchangeIntegralBalance'>
          <a-input v-model='deliver1.form.exchangeIntegralBalance'  disabled="" />
        </a-form-model-item>

        <a-form-model-item label="下单时间" prop='createTime'>
          <a-date-picker :valueFormat="createFormatType" v-model="deliver1.form.createTime" showTime placeholder="选择日期时间"/>
        </a-form-model-item>

        <template>
          <a-card style="width: 90%;margin-left: auto;margin-right: auto">
            <a-descriptions :column="7" >
              <template v-for="(item, index) in this.deliver1.form.productionList">
                <a-descriptions-item style="padding-bottom: 0;" :key="index" :label='item.title' span="3"></a-descriptions-item>
                <a-descriptions-item style="padding-bottom: 0;" :key="index" label='数量' span="2" >
                  <a-input-number @change="changeJiFen" :min='0' v-model='item.quantity' style="width: 60px;"/>
                </a-descriptions-item>
                <a-descriptions-item :key="index" label='兑换积分'  span="2"><span class="color-red">{{ item.price*item.quantity}}</span></a-descriptions-item>
              </template>
            </a-descriptions>
          </a-card>
        </template>

        <template>
          <div style="width: 90%;text-align: right;"> 共计 <span class="color-red">{{ this.deliver1.totalCount}}</span> 件产品，合计：<span class="color-red">¥{{ this.deliver1.totalPrice}}</span>兑换积分</div>
        </template>

        <a-form-model-item label='发货方式' prop='bussType'>
          <a-radio-group name="radioGroup" v-model="deliver1.form.bussType">
            <a-radio value="draw">
              自提现货
            </a-radio>
            <a-radio value="deliver">
              物流发货
            </a-radio>
          </a-radio-group>
        </a-form-model-item>

        <template v-if="deliver1.form.bussType == 'draw'">
          <a-form-model-item label='提货人' prop='drawName'>
            <a-input v-model='deliver1.form.drawName' />
          </a-form-model-item>
          <a-form-model-item label='提货人手机号' prop='drawMobile'>
            <a-input v-model='deliver1.form.drawMobile' />
          </a-form-model-item>
        </template>
        <template v-else>
          <a-form-model-item label='收货人' prop='name'>
            <a-input v-model='deliver1.form.name' />
          </a-form-model-item>
          <a-form-model-item label='收货人手机号' prop='addressMobile'>
            <a-input v-model='deliver1.form.addressMobile' />
          </a-form-model-item>
          <a-form-model-item label='地区' prop='region'>
            <a-cascader
              :field-names="{ label: 'name', value: 'adcode', children: 'districts' }"
              :options="region"
              :show-search="{ filter }"
              :allowClear="false"
              placeholder="请选择"
              @change="onChange"
            />
          </a-form-model-item>
          <a-form-model-item label='详细地址' prop='address'>
            <a-input v-model='deliver1.form.address' />
          </a-form-model-item>
        </template>
      </a-form-model>
    </a-modal>

    <a-modal  :destroyOnClose='true' :maskClosable='true' :footer = "null"
             :visible='deliver3.visible' @cancel='()=>this.deliver3.visible=false' @ok='()=>this.deliver3.visible=false'
             cancel-text='关闭'  :confirmLoading='deliver3.loading' width="95%" >
      <template>

<!--        <a-descriptions title="商品物流出库发货单" class="diyTableStyle_no_Border" style="text-align: center;">-->
<!--        </a-descriptions>-->
<!--        <a-descriptions class="diyTableStyle_no_Border">-->
<!--          <a-descriptions-item label="订单号" >{{ this.deliver3.form.id }}</a-descriptions-item>-->
<!--        </a-descriptions>-->
<!--        <a-descriptions bordered :column="2" class="diyTableStyle">-->
<!--          <a-descriptions-item label="订单商品" :span="2" >{{ this.deliver3.form.orderProductionDesc }}</a-descriptions-item>-->

<!--          <a-descriptions-item label="下单时间" :span="2">{{ this.deliver3.form.createTime }}</a-descriptions-item>-->
<!--&lt;!&ndash;          <a-descriptions-item v-if="this.deliver3.form.orderType =='1'" label="用户支付时间" >{{ this.deliver3.form.auditTime }}</a-descriptions-item>&ndash;&gt;-->
<!--&lt;!&ndash;          <a-descriptions-item v-if="this.deliver3.form.orderType =='2'" label="商户支付时间" >{{ this.deliver3.form.merchantPaymentTime }}</a-descriptions-item>&ndash;&gt;-->

<!--          <a-descriptions-item label="收货人" >{{ this.deliver3.form.name }}</a-descriptions-item>-->
<!--          <a-descriptions-item label="联系电话" >{{ this.deliver3.form.mobile }}</a-descriptions-item>-->

<!--          <a-descriptions-item label="收货地址" :span="2">-->
<!--            {{ this.deliver3.form.addressDesc }}-->
<!--          </a-descriptions-item>-->

<!--          <a-descriptions-item label="经销单位" :span="2">{{ this.deliver3.form.wealthQuotaMemberName }}</a-descriptions-item>-->

<!--          <a-descriptions-item label="发货单位" >{{ this.deliver3.form.logisticsProvider }}</a-descriptions-item>-->
<!--          <a-descriptions-item label="物流单号" >{{ this.deliver3.form.logisticsNo }}</a-descriptions-item>-->

<!--          <a-descriptions-item label="物流金额" >{{ this.deliver3.form.logisticsAmount }}</a-descriptions-item>-->
<!--          <a-descriptions-item label="出库时间" >{{ this.deliver3.form.sendTime }}</a-descriptions-item>-->
<!--        </a-descriptions>-->

<!--        <a-descriptions :column="2" class="diyTableStyle_no_Border">-->
<!--          <a-descriptions-item label="打印人员">{{ this.deliver3.form.outMemberName }}</a-descriptions-item>-->
<!--          <a-descriptions-item label="打印时间" >{{ this.printTime }}</a-descriptions-item>-->
<!--        </a-descriptions>-->

        <a-descriptions title="商品物流出库发货单" class="diyTableStyle_no_Border" style="text-align: center">
        </a-descriptions>
        <a-descriptions :column="4" class="diyTableStyle_no_Border">
          <a-descriptions-item label="订单号" :span="3">{{ this.deliver3.form.id }}</a-descriptions-item>
          <a-descriptions-item label="下单时间" :contentStyle="{textAlign:'right'}">{{ this.deliver3.form.showCreateTime }}</a-descriptions-item>
        </a-descriptions>
        <a-descriptions bordered  :column="2"   class="diyTableStyle">
          <a-descriptions-item label="订单商品" :span="2" >{{ this.deliver3.form.depositoryProductionDesc }}</a-descriptions-item>

          <a-descriptions-item label="收货人" >{{ this.deliver3.form.name }}</a-descriptions-item>
          <a-descriptions-item label="联系电话" >{{ this.deliver3.form.showAddressUserMobile }}</a-descriptions-item>

          <a-descriptions-item label="收货地址" :span="2">
            {{ this.deliver3.form.addressDesc }}
          </a-descriptions-item>

          <a-descriptions-item label="卖方" :span="2">{{ this.deliver3.form.areaNameMai }}</a-descriptions-item>

          <a-descriptions-item label="物流公司" >{{ this.deliver3.form.logisticsProvider  }}</a-descriptions-item>
          <a-descriptions-item label="物流单号" >{{ this.deliver3.form.logisticsNo }}</a-descriptions-item>

          <a-descriptions-item label="物流金额" >寄付月结</a-descriptions-item>
          <!--                    <a-descriptions-item label="物流金额" >{{ address.logisticsAmount }}</a-descriptions-item>-->
          <a-descriptions-item label="出库时间" >{{ this.deliver3.form.sendTime }}</a-descriptions-item>
        </a-descriptions>

        <a-descriptions :column="8" class="diyTableStyle_no_Border">
          <a-descriptions-item label="打印人员" :span="5">{{ this.deliver3.form.outMemberName }}</a-descriptions-item>
          <a-descriptions-item label="打印时间" >{{ this.printTime }}</a-descriptions-item>
        </a-descriptions>
      </template>
    </a-modal>

    <a-modal :destroyOnClose='true' :maskClosable='true' :footer = "null"
             :visible='deliver4.visible' @cancel='()=>this.deliver4.visible=false' @ok='()=>this.deliver4.visible=false'
             cancel-text='关闭'  :confirmLoading='deliver4.loading' width="95%" >
      <template>

<!--        <a-descriptions title="商品提现货出库单" class="diyTableStyle_no_Border" style="text-align: center;">-->
<!--        </a-descriptions>-->
<!--        <a-descriptions >-->
<!--          <a-descriptions-item label="订单号">{{ this.deliver4.form.id }}</a-descriptions-item>-->
<!--        </a-descriptions>-->
<!--        <a-descriptions bordered :column="2" class="diyTableStyle">-->
<!--          <a-descriptions-item label="订单商品" :span="2" >{{ this.deliver4.form.orderProductionDesc }}</a-descriptions-item>-->

<!--          <a-descriptions-item label="下单时间" :span="2">{{ this.deliver4.form.createTime }}</a-descriptions-item>-->
<!--&lt;!&ndash;          <a-descriptions-item v-if="this.deliver4.form.orderType =='1'" label="支付时间" >{{ this.deliver4.form.auditTime }}</a-descriptions-item>&ndash;&gt;-->
<!--&lt;!&ndash;          <a-descriptions-item v-if="this.deliver4.form.orderType =='2'" label="支付时间" >{{ this.deliver4.form.merchantPaymentTime }}</a-descriptions-item>&ndash;&gt;-->

<!--          <a-descriptions-item label="提货人" >{{ this.deliver4.form.drawName }}</a-descriptions-item>-->

<!--          <a-descriptions-item label="提货人手机号" >{{ this.deliver4.form.drawMobile }}</a-descriptions-item>-->

<!--          <a-descriptions-item label="出库人" >{{ this.deliver4.form.outMemberName }}</a-descriptions-item>-->
<!--          <a-descriptions-item label="出库时间" >{{ this.deliver4.form.sendTime }}</a-descriptions-item>-->

<!--          <a-descriptions-item label="提货人签字确认"></a-descriptions-item>-->
<!--          <a-descriptions-item label="日期"></a-descriptions-item>-->
<!--        </a-descriptions>-->

<!--        <a-descriptions :column="2" class="diyTableStyle_no_Border">-->
<!--          <a-descriptions-item label="打印人员">{{ this.deliver4.form.opUserName }}</a-descriptions-item>-->
<!--          <a-descriptions-item label="打印时间" >{{ this.printTime }}</a-descriptions-item>-->
<!--        </a-descriptions>-->

        <a-descriptions title="商品提现货出库单" class="diyTableStyle_no_Border" style="text-align: center;">
        </a-descriptions>
        <a-descriptions class="diyTableStyle_no_Border" :column="4">
          <a-descriptions-item label="订单号" :span="3">{{ this.deliver4.form.id  }}</a-descriptions-item>
          <a-descriptions-item label="下单时间" >{{ this.deliver4.form.showCreateTime }}</a-descriptions-item>
        </a-descriptions>
        <a-descriptions bordered :column="2" class="diyTableStyle">
          <a-descriptions-item label="订单商品" :span="2" >{{ this.deliver4.form.depositoryProductionDesc }}</a-descriptions-item>

          <a-descriptions-item label="提货人" >{{ this.deliver4.form.drawName }}</a-descriptions-item>

          <a-descriptions-item label="提货人手机号" >{{ this.deliver4.form.showAddressUserMobile }}</a-descriptions-item>

          <a-descriptions-item label="卖方" :span="2">{{this.deliver4.form.areaNameMai  }}</a-descriptions-item>

          <a-descriptions-item label="出库人" >{{ this.deliver4.form.outMemberName }}</a-descriptions-item>
          <a-descriptions-item label="出库时间" >{{ this.deliver4.form.sendTime }}</a-descriptions-item>


          <a-descriptions-item label="提货人签字确认"></a-descriptions-item>
          <a-descriptions-item label="日期"></a-descriptions-item>
        </a-descriptions>

        <a-descriptions :column="8" class="diyTableStyle_no_Border">
          <a-descriptions-item label="打印人员" :span="5">{{ this.deliver4.form.opUserName }}</a-descriptions-item>
          <a-descriptions-item label="打印时间" >{{ this.printTime }}</a-descriptions-item>
        </a-descriptions>

      </template>
    </a-modal>

    <a-modal title='物流金额' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver5.visible' @cancel='()=>this.deliver5.visible=false'
             ok-text='确认' cancel-text='取消' @ok='handleDeliver5' :confirmLoading='deliver5.loading' :width="800">
      <a-form-model ref='form5' :model='deliver5.form' :rules='deliver5.rules' :labelCol='{ span: 4 }' :wrapperCol='{ span: 20 }'>
        <a-form-model-item label='物流名称' prop='logisticsProvider'>
          <a-select v-model="deliver5.form.logisticsProvider" placeholder="请选择">
            <a-select-option :value="item" v-for="(item, index) in expressList" :key="index">
              {{item}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label='物流单号' prop='logisticsNo'>
          <a-input v-model='deliver5.form.logisticsNo' />
        </a-form-model-item>
        <a-form-model-item label='物流金额' prop='logisticsAmount'>
          <a-input v-model='deliver5.form.logisticsAmount' />
        </a-form-model-item>
        <a-form-model-item label='付费方式' prop='logisticsMethod'>
          <a-radio-group name="radioGroup" v-model="deliver5.form.logisticsMethod">
            <a-radio :value="1">
              到付
            </a-radio>
            <a-radio :value="2">
              寄付
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

  </page-header-wrapper>
</template>

<script>
import {ChartCard, Ellipsis, STable} from '@/components'
import {
  addExchangeOrder,
  deleteExchangeOrder, drawCommonOrder, drawExchangeOrder, getMemberOrder,
  getMemberOrderAddress,
  getMemberOrderProductions, getMobileMember,
  outExchangeOrder, putMemberOrderAddress, queryCashDetail,
  queryExchangeOrder
} from '@/api/member-order'
import FileSaver from 'file-saver'
import region from '@/config/region'
import XLSX from 'xlsx'
import moment from 'moment'
import {mapGetters} from "vuex";
import {queryDuiHuanProductions} from "@/api/production";


const exchangeColumns = [
  {
    width: 100,
    title: '兑换单号',
    dataIndex: 'id',
    scopedSlots: { customRender: 'id' }
  },
  {
    width: 150,
    title: '兑换商品',
    dataIndex: 'orderProductionDesc',
    scopedSlots: { customRender: 'orderProductionDesc' }
  },
  // {
  //   width: 80,
  //   title: '兑换数量',
  //   dataIndex: 'quantity',
  //   scopedSlots: { customRender: 'quantity' }
  // },
  {
    width: 80,
    title: '兑换分',
    dataIndex: 'consumeAmount',
    scopedSlots: { customRender: 'consumeAmount' }
  },
  {
    width: 80,
    title: '使用现金',
    dataIndex: 'cashAmount',
    scopedSlots: { customRender: 'cashAmount' }
  },
  {
    width: 80,
    title: '兑换人姓名',
    dataIndex: 'memberName',
    scopedSlots: { customRender: 'memberName' }
  },
  {
    width: 80,
    title: '手机号',
    dataIndex: 'memberMobile',
    scopedSlots: { customRender: 'memberMobile' }
  },
  {
    width: 100,
    title: '兑换时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
  {
    width: 100,
    title: '发货时间',
    dataIndex: 'sendTime',
    scopedSlots: { customRender: 'sendTime' }
  },
  {
    width: 130,
    title: '发货信息',
    dataIndex: 'sendDesc',
    scopedSlots: { customRender: 'sendDesc' }
  },
  {
    width: 80,
    title: '分公司简称',
    dataIndex: 'areaName',
    scopedSlots: { customRender: 'areaName' }
  },
  {
    width: 80,
    title: '状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' }
  },
  {
    width: 140,
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

const columns1 = [
  {
    width: 150,
    title: '产品名称',
    dataIndex: 'title',
    scopedSlots: { customRender: 'stitle' }
  },
  {
    width: 100,
    title: '购买数量',
    dataIndex: 'quantity',
    scopedSlots: { customRender: 'quantity' }
  },
]

const exportExchangeColums = [
  {
    width: 80,
    title: '兑换单号',
    dataIndex: 'id',
    scopedSlots: { customRender: 'id' }
  },
  {
    width: 100,
    title: '兑换商品',
    dataIndex: 'orderProductionDesc',
    scopedSlots: { customRender: 'orderProductionDesc' }
  },
  {
    width: 100,
    title: '所用兑换积分',
    dataIndex: 'consumeAmount',
    scopedSlots: { customRender: 'consumeAmount' }
  },
  {
    width: 80,
    title: '兑换人姓名',
    dataIndex: 'memberName',
    scopedSlots: { customRender: 'memberName' }
  },
  {
    width: 150,
    title: '兑换人手机号',
    dataIndex: 'memberMobile',
    scopedSlots: { customRender: 'memberMobile' }
  },
  {
    width: 200,
    title: '兑换时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
  {
    width: 150,
    title: '兑换详情',
    dataIndex: 'orderProductionDesc',
    scopedSlots: { customRender: 'orderProductionDesc' }
  },
  {
    width: 150,
    title: '发货时间',
    dataIndex: 'sendTime',
    scopedSlots: { customRender: 'sendTime' }
  },
  {
    width: 150,
    title: '发货信息',
    dataIndex: 'sendDesc',
    scopedSlots: { customRender: 'sendDesc' }
  }
]

const formatType = 'YYYY-MM-DD HH:mm';
const baseStart = moment().startOf('month').format(formatType);
// const baseEnd = moment().format(formatType);
const baseEnd = moment().startOf('day').format('YYYY-MM-DD')+ ' 23:59';

const createFormatType = 'YYYY-MM-DD HH:mm:ss';//录单时候页用到，不要乱改

export default {
  name: 'InsideStorehouseExchangeList',
  components: {
    STable, Ellipsis, ChartCard
  },
  data() {
    return {
      printTime:'',
      dashboard: {},
      selectedRowKeys: [],
      // 筛选表单
      formatType,
      dateArr: [baseStart, baseEnd],
      form: {
        start: baseStart,
        end: baseEnd,
      },
      address: {},
      loading: true,
      total: 0,
      // 表头
      exchangeColumns,
      createFormatType,
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        let params = Object.assign(parameter, this.form);
        // debugger;
        return queryExchangeOrder(params)
          .then(datum => {
            this.total = datum.ext1;
            this.$emit("setExchangeTitle", this.total);//
            return datum
          })
      },
      deliver: { visible: false,loading: false, form: {},
        rules: {
          logisticsNo: [{ required: true, message: '请填写物流单号', trigger: 'change' }],
          logisticsProvider: [{ required: true, message: '请填写物流名称', trigger: 'change' }],
          logisticsAmount: [{ required: true, message: '请填写物流金额', trigger: 'change' }]
        },
      },
      deliver1: { visible: false,loading: false, form: {},
        totalPrice:0,//积分订单商品价格
        totalCount:0,//积分订单商品数量
        rules: {
          mobile: [{ required: true, message: '请填写下单账号（手机）', trigger: 'change' },],
          createTime: [{ required: true, message: '请选择', trigger: 'change' }],
          quantity: [
            { required: true, message: '请填写商品数量', trigger: 'change' },
            { type: 'integer', message: '商品数量填写整数', trigger: 'change' }
          ],
          otherPay: [{ required: true, }],
          otherName: [{ required: true, message: '请输入代付人姓名', trigger: 'change' }],
          bussType: [{ required: true, message: '请选择发货方式', trigger: 'change' }],
          name: [{ required: true, message: '请填写收货人', trigger: 'change' }],
          addressMobile: [{ required: true, message: '请填写收货人手机号', trigger: 'change' }],
          region: [{ required: true, message: '请选择地区', trigger: 'change' }],
          address: [{ required: true, message: '请填写地址', trigger: 'change' }],
          drawName: [{ required: true, message: '请填写提货人', trigger: 'change' }],
          drawMobile: [{ required: true, message: '请填写提货人手机号', trigger: 'change' }]
        },
      },
      deliver2: { visible: false,loading: false, form: {},
        rules: {
          logisticsNo: [{ required: true, message: '请填写物流单号', trigger: 'change' }],
          logisticsProvider: [{ required: true, message: '请填写物流名称', trigger: 'change' }],
          logisticsAmount: [{ required: true, message: '请填写物流金额', trigger: 'change' }]
        },
      },
      deliver3: {
        visible: false,
        loading: false,
        form: {},
      },
      deliver4: {
        visible: false,
        loading: false,
        form: {},
      },
      deliver5: { visible: false,loading: false, form: {},
        rules: {
          logisticsNo: [{ required: true, message: '请填写物流单号', trigger: 'change' }],
          logisticsProvider: [{ required: true, message: '请填写物流名称', trigger: 'change' }],
          logisticsAmount: [{ required: true, message: '请填写物流金额', trigger: 'change' }]
        },
      },
      region: region,
      columns1,
      loadData1: parameter => {
        let queryParam = {};
        if (this.deliver.form.id) {
          queryParam.order = this.deliver.form.id;
        }
        return getMemberOrderProductions(Object.assign(parameter, queryParam))
          .then(datum => {
            return datum
          })
      },
    }
  },
  computed: {
    ...mapGetters(["expressList"])
  },
  created() {},
  methods: {
    moment,
    productListStr(text){
      return text.split(",");

    },
    sendInfoDesc(record){
      let info =[];
      if(record.bussType=="deliver"){
        let obj = record.address;
        let address = obj.regionOneName+obj.regionTwoName+obj.regionThreeName+obj.address;
        let name = obj.name+":"+obj.mobile;
        info.push(name);
        info.push(address);
      }else if(record.bussType=='draw'){
        info.push("提货人："+record.drawName);
        info.push("手机号："+record.drawMobile);
      }

      return info;

    },
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    handleDeleteAll() {
      const requestList = [];
      this.selectedRowKeys.forEach(item => {     
         requestList.push(deleteExchangeOrder({ id: item }))
      });
      if (requestList.length == 0) {
        this.$message.warning('至少选择一个订单');
        return;
      }
      Promise.all(requestList).then(result => {
        this.$message.success('操作成功');
        this.handleInitial();
      })

    },
    dateChange() {
      this.form.start =  this.dateArr[0] ? moment(this.dateArr[0]).format(this.formatType) : "";
      this.form.end =  this.dateArr[1] ? moment(this.dateArr[1]).format(this.formatType) : "";
    },
    handleReset() {
      this.dateArr = [baseStart, baseEnd];
      this.form.start = baseStart;
      this.form.end = baseEnd;
      this.$refs.form.resetFields()
      this.$refs.exchangeTable.refresh(true)
    },
    handleSubmit() {
      this.$refs.exchangeTable.refresh(true)
    },
    exportToExcel1 () {
      this.exchangeColumns = exportExchangeColums;
      this.$nextTick(() => {
        let selector = document.querySelector('#exchangeTable');
        let et = XLSX.utils.table_to_book(selector,{ raw: true }); //此处传入table的DOM节点
        let etout = XLSX.write(et, {
          bookType: 'xlsx',
          bookSST: true,
          type: 'array'
        });
        try {
            FileSaver.saveAs(new Blob([etout], {
                type: 'application/octet-stream'
            }), '兑换订单.xlsx');   //trade-publish.xlsx 为导出的文件名
        } catch (e) {
            console.log(e, etout) ;
        }
        this.exchangeColumns = exchangeColumns
        return etout;
      })
    },
    handleDelete(record) {
      deleteExchangeOrder({ id: record.id }).then(result => {
        this.$message.success('操作成功')
        this.$refs.exchangeTable.refresh(true)
      })
    },

    initialDeliver(record) {
      if(record.bussType=='draw'){
        //物流
        this.deliver2.loading = true
        queryCashDetail({id:record.id}).then(result => {
          this.deliver2.form = {
            id: record.id,
            productionList: record.productions,
            bussType: record.bussType,
            drawName: record.drawName,
            drawMobile: record.drawMobile,
          };
          this.deliver2.loading = false
          this.deliver2.visible = true
        });
      }else if(record.bussType=='deliver'){
        //自提
        this.deliver1.loading = true
        getMemberOrderAddress({ order: record.id }).then(result => {
          this.deliver.form = { id: record.id, address: Object.assign({}, this.deliver.form.address, result)}
          this.deliver.visible = true
          this.$nextTick(() => {
            this.$refs.table1.refresh(true);
          })
        })
      }
    },
    //打开手动录入兑换订单窗口
    initialExchangeWin(){
      let _this = this;
      let prams = { current: 1, size: 1000 };
      queryDuiHuanProductions(prams).then(p => {
        let productions = this.cuProductList(p);

        this.deliver1.form = {
          createTime: moment().format(createFormatType),
          exchangeIntegralBalance:0,
          productionList: productions,
          bussType:'deliver',
        };

        this.changeJiFen();

        _this.deliver1.loading = false
        _this.deliver1.visible = true
      });

    },
    //计算全部商品列表组合显示
    cuProductList(result){
      let pl = result.records;//全部商品
      let production = [];
      for(let p in pl){
        let it = pl[p]
        it.quantity = 0;
        it.amount = 0;
        it.production = it.id;
      }
      //过滤订单产品，并且合并商品列表
      for(let p in pl){
        let it = pl[p]
        production.push(it);
      }
      return production;
    },
    //积分更改产品显示价格
    changeJiFen(){
      let count = 0;
      if (this.deliver1.form.productionList) {
        for (const item of this.deliver1.form.productionList) {
          count += item.quantity;
        }
      }
      let price = 0;
      if (this.deliver1.form.productionList) {
        for (const item of this.deliver1.form.productionList) {
          item.price = item.price ? item.price : 0;
          price += item.quantity * item.price;
        }
      }
      this.deliver1.totalPrice = price;
      this.deliver1.totalCount = count;
    },
    //动态搜索手机号
    onChangeMobile(){
      if (this.deliver1.form.mobile.length >= 9) {
        this.getMerchantInfo();
      } else {
        this.$set(this.deliver1.form, "exchangeIntegralBalance", 0);
      }
    },
    getMerchantInfo() {
      getMobileMember({ phone: this.deliver1.form.mobile }).then(result => {
        //设置兑换积分
        this.$set(this.deliver1.form, "exchangeIntegralBalance", result.exchangeIntegralBalance);
      }).catch(e=>{
        this.$set(this.deliver1.form, "exchangeIntegralBalance", 0);
      })
    },
    handleDeliver1(){
      this.$refs.form1.validate((result) => {
        let otherPay = this.deliver1.form.otherPay;
        if (result) {
          let exBalance = this.deliver1.form.exchangeIntegralBalance;
          let totalPrice =  this.deliver1.totalPrice
          if (otherPay == 'false') {
            let otherName = this.deliver1.form.bankTransBuyerName;
            if (!otherName) {
              this.$message.error("选择代付，必须要输入代付人姓名");
              return;
            }
          }else if(this.deliver1.totalCount<1){
              this.$message.error("请选择最少一个兑换商品！");
              return;
          }else if(exBalance <totalPrice){
              this.$message.error("当前可用兑换积分太少，不足以支付当前总兑换积分");
              return;
          }
          this.addOrder();
        }
      });

    },
    //录单添加主体
    addOrder() {
      if (this.deliver1.form.region && this.deliver1.form.region.length > 0) {
        this.deliver1.form.regionOneCode = this.deliver1.form.region[0].adcode;
        this.deliver1.form.regionOneName = this.deliver1.form.region[0].name;
        this.deliver1.form.regionTwoCode = this.deliver1.form.region[1].adcode;
        this.deliver1.form.regionTwoName = this.deliver1.form.region[1].name;
        this.deliver1.form.regionThreeCode = this.deliver1.form.region[2].adcode;
        this.deliver1.form.regionThreeName = this.deliver1.form.region[2].name;
      }

      const parameter = JSON.parse(JSON.stringify(this.deliver1.form));
      addExchangeOrder(parameter).then(result => {
          this.$message.success('添加成功')
          this.$refs.exchangeTable.refresh(true)
          this.deliver1.visible = false
          this.deliver1.loading = false;
      }).catch((e) => {
        this.deliver1.loading = false;
      })
    },
    onChange(value, selectedOptions) {
      this.$set(this.deliver1.form, "region", selectedOptions)
    },
    filter(inputValue, path) {
      return path.some(option => option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    },
    //确认收货
    handleDeliver() {
      this.$refs.form.validate((result) => {
        if (result) {
          this.deliver.loading = true;
          let param = Object.assign(this.deliver.form);
          outExchangeOrder(param).then(_result => {
            this.$message.success('操作成功')
            this.$refs.exchangeTable.refresh(true)
            this.deliver.visible = false
            this.deliver.loading = false;
          }).catch(() => {
            this.deliver.loading = false;
          })
        }
      })
    },
    //确认自提//兑换订单
    handleConfirm(record) {

        this.deliver2.loading = true;
        drawExchangeOrder(this.deliver2.form).then(_result => {
          this.$message.success('操作成功')
          this.$refs.exchangeTable.refresh(true)
          this.deliver2.visible = false
          this.deliver2.loading = false;
        }).catch(() => {
          this.deliver2.loading = false;
        })
    },
    showChuHuo(record){
      this.printTime =moment().format(formatType);
      let params = {
        id: record.id
      }

      let merchantMember = record.merchantMember;
      let areaName = "";
      // 1）用户支付给商户，商户再支付给公司，卖方显示商户所属分公司名称；（有merchantMember）取merchantAreaName;
      // 2）用户/备选/商户下单直接支付给公司的，卖方显示自己所属分公司名称；（没有merchantMember）取memberAreaName;
      if(merchantMember){
        areaName = record.merchantAreaName;
      }else{
        areaName = record.memberAreaName;
      }
      // debugger;

      if(status == "draw"){
        //自提
        this.deliver4.visible=true;
        this.deliver4.form = record;
        this.deliver4.form.showCreateTime = this.getCurrentTime(record.createTime);

        this.deliver4.form.areaNameMai = areaName;

        let addressUserMobile = record.drawMobile;
        let tempString = addressUserMobile.toString().substring(0,8)+"***";
        record.showAddressUserMobile =tempString;

        let status = record.bussType;


      }else {
        // 物流
        this.deliver3.form = record;
        let address = this.sendInfoDesc(record);
        let mobile = record.address.mobile;
        let name = record.address.name;
        let logisticsProvider =record.address.logisticsProvider;
        let logisticsNo = record.address.logisticsNo;
        let logisticsAmount = record.address.logisticsAmount;

        let addressUserMobile = record.address.mobile;
        let tempString = addressUserMobile.toString().substring(0,8)+"***";
        record.showAddressUserMobile =tempString;

        this.deliver3.form.showCreateTime = this.getCurrentTime(record.createTime);
        this.deliver3.form.name = name;
        this.deliver3.form.addressDesc = address[1];
        this.deliver3.form.mobile = mobile;
        this.deliver3.form.logisticsProvider = logisticsProvider;
        this.deliver3.form.logisticsNo = logisticsNo;
        this.deliver3.form.logisticsAmount = logisticsAmount;

        this.deliver3.form.areaNameMai = areaName;

        this.deliver3.visible=true;
      }



    },
    getCurrentTime(createTime) {
      const now = new Date(createTime);
      const hours = now.getHours();
      const minutes = now.getMinutes();
      const seconds = now.getSeconds();

      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    },
    initialDeliver1(record) {
      // let logisticsProvider =record.logisticsProvider;
      let address = record.address;

      this.deliver5.form = {
        id: address.id,
        logisticsMethod: address.logisticsMethod||2,
        logisticsAmount:address.logisticsAmount,
        logisticsNo:address.logisticsNo,
        logisticsProvider:address.logisticsProvider,
      };
      debugger

      this.deliver5.visible = true;


    },
    handleDeliver5(){
      this.$refs.form5.validate((result) => {
        if (result) {
          this.deliver5.loading = true;
          let params = Object.assign(this.deliver5.form);
          // debugger;
          putMemberOrderAddress(params).then(_result => {
            this.$message.success('操作成功')
            this.$refs.exchangeTable.refresh(true)
            this.deliver5.visible = false
            this.deliver5.loading = false;
          }).catch(() => {
            this.deliver5.loading = false;
          })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.total {
  font-size: 20px;
}
/deep/.ant-page-header{
  display: none;
}

/deep/.ant-modal {
  top: 1px; /* 距离顶部50像素 */
}

/deep/.diyTableStyle {
  font-weight: bold;
  font-family: "黑体", "微软雅黑", "宋体", serif;
  color: #000000;
}

/deep/.diyTableStyle .ant-descriptions-row {
  border:1px solid #000000;
  font-family: "黑体", "微软雅黑", "宋体", serif;
  color: #000000;
  font-weight: bold;
}

/deep/.diyTableStyle .ant-descriptions-item-content{
  border-right: 1px solid #000000;
  padding: 10px 5px;
  font-weight: bold;
  font-family: "黑体", "微软雅黑", "宋体", serif;
  color: #000000;
}
/deep/.diyTableStyle .ant-descriptions-item-label{
  padding: 10px 5px;
  font-weight: bold;
  font-family: "黑体", "微软雅黑", "宋体", serif;
  color: #000000;
  background-color: #ffffff;
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  border-right: 1px solid #000000;
}


/deep/.diyTableStyle_no_Border {
  font-weight: bold;
  font-family: "黑体", "微软雅黑", "宋体", serif;
  color: #000000;
}

/deep/.diyTableStyle_no_Border .ant-descriptions-row {
  font-family: "黑体", "微软雅黑", "宋体", serif;
  color: #000000;
  font-weight: bold;
}

/deep/.diyTableStyle_no_Border .ant-descriptions-item-content{
  font-weight: bold;
  font-family: "黑体", "微软雅黑", "宋体", serif;
  color: #000000;
}
/deep/.diyTableStyle_no_Border .ant-descriptions-item-label{
  font-weight: bold;
  font-family: "黑体", "微软雅黑", "宋体", serif;
  color: #000000;
  background-color: #ffffff;
}
</style>

