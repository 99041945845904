<template>
  <page-header-wrapper style="margin: unset;">
    <a-form-model ref='form' :model='form' layout='inline'>

      <a-form-model-item>
      <a-select v-model="form.areaOrderType" placeholder="订单类型" style="width: 250px" @change="changeAreaType">
        <a-select-option  value="1">
          积分订单
        </a-select-option>
        <a-select-option  value="2">
          兑换订单
        </a-select-option>
      </a-select>
      </a-form-model-item>

      <a-form-model-item prop='areaName'>
        <a-input v-model='form.areaName' style="width: 300px" placeholder="分公司名称" />
      </a-form-model-item>
      <a-form-model-item prop='status'>

        <a-select v-model="form.status" placeholder="状态" style="width: 250px">
          <a-select-option  value="delivering">
            待提货
          </a-select-option>
          <a-select-option  value="finished">
            已完成
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item prop='start'>
        <a-range-picker
          v-model="dateArr"
          :format="[formatType, formatType]"
          :show-time="{
            hideDisabledOptions: true,
          }"
          :disabled-date="disabledDate"
          @change="dateChange"
        />
      </a-form-model-item>

      <a-form-model-item>
        <a-button type='primary' @click='handleSubmit'>
          查询
        </a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click='handleReset'>
          重置
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <div>
      <div class='table-operator' style="margin-top: 20px">
        <a-button type='primary' icon='reload' @click='$refs.table.refresh(true)'>刷新</a-button>
        <a-button type='primary' icon='table' @click='exportToExcel1'>导出</a-button>
      </div>

      <s-table id="table" ref='table' rowKey='id' size='default' :columns='officeColumns' :scroll='{ x: 1500}' :data='loadData' :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
        <span slot='status' slot-scope='text, record'>
          <template>
              <span v-if="text=='delivering'">待提货</span>
              <span v-if="text=='receiving'">已提货</span>
              <span v-if="text=='finished'">已完成</span>
          </template>
        </span>
        <span slot='areaOrderType' slot-scope='text, record'>
          <template>
            <span v-if="form.areaOrderType=='1'">积分订单</span>
            <span v-if="form.areaOrderType=='2'">兑换订单</span>
          </template>
        </span>
        <span slot='action' slot-scope='text, record'>
          <template>
            <a-space>
              <a-space>
                  <a href='#' @click='showChuHuo(record)'> 打印出库单</a>
              </a-space>

              <a v-if="record.status == 'delivering'" @click='initialDeliver1(record)'>修改商品</a>

              <a v-if="record.status == 'delivering'" @click='initialDeliver(record)'>确定提货</a>

              <a-popconfirm title='确定删除吗？' ok-text='确认' cancel-text='取消' @confirm='handleDelete(record)'>
                <a href='#'>删除</a>
              </a-popconfirm>
            </a-space>
          </template>
        </span>
      </s-table>
    </div>

    <a-modal title='确认提货' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver.visible' @cancel='()=>this.deliver.visible=false'
             ok-text='确认' cancel-text='取消' @ok='handleConfirm' :confirmLoading='deliver.loading' width="70%">
      <a-form-model ref='form' :model='deliver.form' :rules='deliver.rules' :labelCol='{ span: 4 }' :wrapperCol='{ span: 20 }'>
        <a-card style="width: 90%;margin-left: auto;margin-right: auto">

          <a-descriptions title="订单购买明细" :column="3" bordered >
            <template v-for="(item, index) in deliver.form.productionList" >
              <a-descriptions-item :key="index+'1'" label='商品名称'>{{item.title}}</a-descriptions-item>
              <a-descriptions-item :key="index+'2'" label='价格'>
                                <span v-if="form.areaOrderType=='1'">￥{{item.price}}</span>
                                <span v-if="form.areaOrderType=='2'">{{item.price}}积分</span>
              </a-descriptions-item>
              <a-descriptions-item :key="index+'3'" label='数量'>{{item.quantity}}</a-descriptions-item>
            </template>
          </a-descriptions>

          <a-descriptions  :column="4" bordered layout="vertical">
            <a-descriptions-item  label='发货方式' >
              <a-radio-group name="radioGroup" v-model="deliver.form.bussType" disabled>
              <a-radio value="draw">
                自提现货
              </a-radio>
              <a-radio value="deliver">
                物流发货
              </a-radio>
            </a-radio-group>
            </a-descriptions-item>
            <a-descriptions-item  label='订单号'>
              {{deliver.form.id}}
            </a-descriptions-item>
            <a-descriptions-item  label='提货人'>
              {{deliver.form.drawName}}
            </a-descriptions-item>
            <a-descriptions-item  label='提货人手机号'>
              {{deliver.form.drawMobile}}
            </a-descriptions-item>
          </a-descriptions>

        </a-card>
      </a-form-model>
    </a-modal>

    <a-modal title='修改商品' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver1.visible' @cancel='()=>this.deliver1.visible=false'
             ok-text='确认' cancel-text='取消' @ok='handleJiFenDeliver' :confirmLoading='deliver1.loading' width="50%">
      <a-form-model ref='form1' :model='deliver1.form' :rules='deliver1.rules' :labelCol='{ span: 8 }' :wrapperCol='{ span: 16 }'>
        <a-card style="width: 90%;margin-left: auto;margin-right: auto">
          <a-row v-for="(item, index) in deliver1.form.productionList" :key="index">
            <a-col :span="12">
              <a-form-model-item label='商品名称'>
                {{item.title}}
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label='价格'>
                <span v-if="form.areaOrderType=='1'">￥{{item.price}}</span>
                <span v-if="form.areaOrderType=='2'">{{item.price}}积分</span>
              </a-form-model-item>
            </a-col>
            <a-col :span="4">
              <a-form-model-item label='数量'>
                <a-input-number v-model='item.quantity' :min="0" @change="changeJiFen"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
            </a-col>
            <a-col :span="12">
              <a-form-model-item label='价格'>
                共计 {{ this.deliver1.totalCount}} 件产品，合计：¥{{ this.deliver1.totalPrice}}
              </a-form-model-item>
            </a-col>
          </a-row>

        </a-card>

        <a-form-model-item label='发货方式' prop='bussType'>
          <a-radio-group name="radioGroup" v-model="deliver1.form.bussType" disabled>
            <a-radio value="draw">
              自提现货
            </a-radio>
            <a-radio value="deliver">
              物流发货
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <template v-if="deliver1.form.bussType == 'draw'">
          <a-form-model-item label='提货人' prop='drawName'>
            <a-input v-model='deliver1.form.drawName' />
          </a-form-model-item>
          <a-form-model-item label='提货人手机号' prop='drawMobile'>
            <a-input v-model='deliver1.form.drawMobile' />
          </a-form-model-item>
        </template>
        <template v-else>
          <a-form-model-item label='收货人' prop='name'>
            <a-input v-model='deliver1.form.name' />
          </a-form-model-item>
          <a-form-model-item label='收货人手机号' prop='addressMobile'>
            <a-input v-model='deliver1.form.addressMobile' />
          </a-form-model-item>
          <a-form-model-item label='地区' prop='region'>
            <a-cascader
              :defaultValue="[deliver1.form.regionOneCode, deliver1.form.regionTwoCode, deliver1.form.regionThreeCode]"
              :field-names="{ label: 'name', value: 'adcode', children: 'districts' }"
              :options="region"
              :show-search="{ filter }"
              :allowClear="false"
              placeholder="请选择"
              @change="onChange"
            />
          </a-form-model-item>
          <a-form-model-item label='详细地址' prop='address'>
            <a-input v-model='deliver1.form.address' />
          </a-form-model-item>
        </template>
      </a-form-model>
    </a-modal>



    <a-modal  :destroyOnClose='true' :maskClosable='true' :footer = "null"
             :visible='deliver4.visible' @cancel='()=>this.deliver4.visible=false' @ok='()=>this.deliver4.visible=false'
             cancel-text='关闭'  :confirmLoading='deliver4.loading' width="95%">
      <template>

        <a-descriptions title="商品提现货出库单" class="diyTableStyle_no_Border" style="text-align: center;" >
        </a-descriptions>
<!--        <a-descriptions class="diyTableStyle_no_Border">-->
<!--          <a-descriptions-item label="订单号">{{ this.deliver4.form.id }}</a-descriptions-item>-->
<!--        </a-descriptions>-->
<!--        <a-descriptions bordered :column="2" class="diyTableStyle">-->
<!--          <a-descriptions-item label="订单商品" :span="2" >{{ this.deliver4.form.orderProductionDesc }}</a-descriptions-item>-->

<!--          <a-descriptions-item label="下单时间" :span="2">{{ this.deliver4.form.createTime }}</a-descriptions-item>-->
<!--&lt;!&ndash;          <a-descriptions-item v-if="this.deliver4.form.orderType =='1'" label="用户支付时间" >{{ this.deliver4.form.auditTime }}</a-descriptions-item>&ndash;&gt;-->
<!--&lt;!&ndash;          <a-descriptions-item v-if="this.deliver4.form.orderType =='2'" label="商户支付时间" >{{ this.deliver4.form.merchantPaymentTime }}</a-descriptions-item>&ndash;&gt;-->

<!--          <a-descriptions-item label="提货人" >{{ this.deliver4.form.drawName }}</a-descriptions-item>-->

<!--          <a-descriptions-item label="提货人手机号" >{{ this.deliver4.form.drawMobile }}</a-descriptions-item>-->

<!--          <a-descriptions-item label="出库人" >{{ this.deliver4.form.outMemberName }}</a-descriptions-item>-->
<!--          <a-descriptions-item label="出库时间" >{{ this.deliver4.form.sendTime }}</a-descriptions-item>-->

<!--          <a-descriptions-item label="提货人签字确认"></a-descriptions-item>-->
<!--          <a-descriptions-item label="日期"></a-descriptions-item>-->
<!--        </a-descriptions>-->

<!--        <a-descriptions :column="2" class="diyTableStyle_no_Border">-->
<!--          <a-descriptions-item label="打印人员" >{{ this.deliver4.form.opUserName }}</a-descriptions-item>-->
<!--          <a-descriptions-item label="打印时间" >{{ this.printTime }}</a-descriptions-item>-->
<!--        </a-descriptions>-->
        <a-descriptions class="diyTableStyle_no_Border" :column="4">
          <a-descriptions-item label="订单号" :span="3">{{ this.deliver4.form.id  }}</a-descriptions-item>
          <a-descriptions-item label="下单时间" >{{ this.deliver4.form.showCreateTime }}</a-descriptions-item>
        </a-descriptions>
        <a-descriptions bordered :column="2" class="diyTableStyle">
          <a-descriptions-item label="订单商品" :span="2" >{{ this.deliver4.form.depositoryProductionDesc }}</a-descriptions-item>

          <a-descriptions-item label="提货人" >{{ this.deliver4.form.drawName }}</a-descriptions-item>

          <a-descriptions-item label="提货人手机号" >{{ this.deliver4.form.showAddressUserMobile }}</a-descriptions-item>

          <a-descriptions-item label="卖方" :span="2">{{ this.deliver4.form.areaNameMai }}</a-descriptions-item>

          <a-descriptions-item label="出库人" >{{ this.deliver4.form.outMemberName }}</a-descriptions-item>
          <a-descriptions-item label="出库时间" >{{ this.deliver4.form.sendTime }}</a-descriptions-item>


          <a-descriptions-item label="提货人签字确认"></a-descriptions-item>
          <a-descriptions-item label="日期"></a-descriptions-item>
        </a-descriptions>

        <a-descriptions :column="8" class="diyTableStyle_no_Border">
          <a-descriptions-item label="打印人员" :span="5">{{ this.deliver4.form.opUserName }}</a-descriptions-item>
          <a-descriptions-item label="打印时间" >{{ this.printTime }}</a-descriptions-item>
        </a-descriptions>
      </template>
    </a-modal>

  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis, ChartCard } from '@/components'
import {
  queryExchangeOrder,
  getMemberOrder,
  outExchangeOrder,
  deleteExchangeOrder,
  deliverMemberOrder,
  getMemberOrderAddress,
  getMemberOrderProductions,
  queryAreaOrder,
  deleteMemberOrder,
  putOrderProduction,
  queryCashDetail,
  drawExchangeOrder,
  drawCommonOrder,
  putExchangeProduction
} from '@/api/member-order'
import FileSaver from 'file-saver'
import XLSX from 'xlsx'
import moment from 'moment'
import {mapGetters} from "vuex";
import {queryDuiHuanProductions, queryProductions} from "@/api/production";
import region from "@/config/region";

const officeColumns = [
  {
    width: 100,
    title: '订单类型',
    dataIndex: 'areaOrderType',
    scopedSlots: { customRender: 'areaOrderType' }
  },
  {
    width: 80,
    title: '订单号',
    dataIndex: 'id',
    scopedSlots: { customRender: 'id' }
  },
  {
    width: 120,
    title: '订单商品',
    dataIndex: 'orderProductionDesc',
    scopedSlots: { customRender: 'orderProductionDesc' }
  },
  {
    width: 80,
    title: '订单金额',
    dataIndex: 'amount',
    scopedSlots: { customRender: 'amount' }
  },
  {
    width: 100,
    title: '分公司名称',
    dataIndex: 'areaName',
    scopedSlots: { customRender: 'areaName' }
  },
  {
    width: 100,
    title: '下单时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
  {
    width: 100,
    title: '出库时间',
    dataIndex: 'sendTime',
    scopedSlots: { customRender: 'sendTime' }
  },
  {
    width: 80,
    title: '状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' }
  },
  {
    width: 150,
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

const columns1 = [
  {
    width: 150,
    title: '产品名称',
    dataIndex: 'title',
    scopedSlots: { customRender: 'stitle' }
  },
  {
    width: 100,
    title: '购买数量',
    dataIndex: 'quantity',
    scopedSlots: { customRender: 'quantity' }
  },
]

const exportExchangeColums = [
  {
    width: 100,
    title: '兑换单号',
    dataIndex: 'id',
    scopedSlots: { customRender: 'id' }
  },
  {
    width: 100,
    title: '兑换商品',
    dataIndex: 'orderName',
    scopedSlots: { customRender: 'orderName' }
  },
  {
    width: 100,
    title: '所用兑换积分',
    dataIndex: 'consumeAmount',
    scopedSlots: { customRender: 'consumeAmount' }
  },
  {
    width: 100,
    title: '兑换人姓名',
    dataIndex: 'memberName',
    scopedSlots: { customRender: 'memberName' }
  },
  {
    width: 150,
    title: '兑换人手机号',
    dataIndex: 'memberMobile',
    scopedSlots: { customRender: 'memberMobile' }
  },
  {
    width: 200,
    title: '兑换时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
  {
    width: 150,
    title: '兑换详情',
    dataIndex: 'orderProductionDesc',
    scopedSlots: { customRender: 'orderProductionDesc' }
  },
  {
    width: 150,
    title: '发货时间',
    dataIndex: 'sendTime',
    scopedSlots: { customRender: 'sendTime' }
  },
  {
    width: 150,
    title: '发货信息',
    dataIndex: 'outTime1',
    scopedSlots: { customRender: 'outTime1' }
  }
]

const formatType = 'YYYY-MM-DD HH:mm';
const baseStart = moment().startOf('month').format(formatType);
// const baseEnd = moment().format(formatType);
const baseEnd = moment().startOf('day').format('YYYY-MM-DD')+ ' 23:59';

export default {
  name: 'InsideStorehouseExchangeList',
  components: {
    STable, Ellipsis, ChartCard
  },
  data() {
    return {
      printTime:'',
      dashboard: {},
      selectedRowKeys: [],
      // 筛选表单
      formatType,
      dateArr: [baseStart, baseEnd],
      form: {
        start: baseStart,
        end: baseEnd,
        areaOrderType:'1'
      },
      loading: true,
      total: 0,
      address: {},
      // 表头
      officeColumns,
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        let params = Object.assign(parameter, this.form);
        return queryAreaOrder(params).then(datum => {
          let total = datum.total;
          // debugger;
            this.$emit("setOfficeTitle", total);
            return datum
          })
      },
      deliver: { visible: false,loading: false, form: {},
        rules: {
          logisticsNo: [{ required: true, message: '请填写物流单号', trigger: 'change' }],
          logisticsProvider: [{ required: true, message: '请填写物流名称', trigger: 'change' }],
          logisticsAmount: [{ required: true, message: '请填写物流金额', trigger: 'change' }]
        },
      },
      region: region,
      deliver1: {
        totalPrice:0,//积分订单商品价格
        totalCount:0,//积分订单商品数量
        visible: false,
        loading: false,
        form: {},
        bussType:'draw',
        rules: {
          bussType: [{ required: true, message: '请选择发货方式', trigger: 'change' }],
          name: [{ required: true, message: '请填写收货人', trigger: 'change' }],
          addressMobile: [{ required: true, message: '请填写收货人手机号', trigger: 'change' }],
          region: [{ required: true, message: '请选择地区', trigger: 'change' }],
          address: [{ required: true, message: '请填写地址', trigger: 'change' }],
          drawName: [{ required: true, message: '请填写提货人', trigger: 'change' }],
          drawMobile: [{ required: true, message: '请填写提货人手机号', trigger: 'change' }]
        }
      },
      deliver3: {
        visible: false,
        loading: false,
        form: {},
      },
      deliver4: {
        visible: false,
        loading: false,
        form: {},
      },
      columns1,
      loadData1: parameter => {
        let queryParam = {};
        if (this.deliver.form.id) {
          queryParam.order = this.deliver.form.id;
        }
        let params = Object.assign(parameter, queryParam);
        return getMemberOrderProductions(params).then(datum => {
            return datum
          })
      },
    }
  },
  computed: {
    ...mapGetters(["expressList"])
  },
  created() {

  },
  methods: {
    changeAreaType(){
      let col3 = this.officeColumns[3];
      // debugger;
      if(this.form.areaOrderType=='1'){
        col3.title='订单金额';
        col3.dataIndex='amount';
      }else{
        col3.title='订单积分';
        col3.dataIndex='consumeAmount';
      }
      this.$refs.table.refresh(true)
    },
    moment,
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    filter(inputValue, path) {
      return path.some(option => option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    },
    onChange(value, selectedOptions) {
      this.$set(this.deliver1.form, "region", selectedOptions)
    },
    dateChange() {
      this.form.start =  this.dateArr[0] ? moment(this.dateArr[0]).format(this.formatType) : "";
      this.form.end =  this.dateArr[1] ? moment(this.dateArr[1]).format(this.formatType) : "";
    },
    handleReset() {
      this.dateArr = [baseStart, baseEnd];
      this.form.start = baseStart;
      this.form.end = baseEnd;
      this.$refs.form.resetFields()
      this.$refs.table.refresh(true)
    },
    handleSubmit() {
      this.$refs.table.refresh(true)
    },
    exportToExcel1 () {
      this.exchangeColumns = exportExchangeColums;
      this.$nextTick(() => {
        let selector = document.querySelector('#exchangeTable');
        let et = XLSX.utils.table_to_book(selector,{ raw: true }); //此处传入table的DOM节点
        let etout = XLSX.write(et, {
          bookType: 'xlsx',
          bookSST: true,
          type: 'array'
        });
        try {
            FileSaver.saveAs(new Blob([etout], {
                type: 'application/octet-stream'
            }), '兑换订单.xlsx');   //trade-publish.xlsx 为导出的文件名
        } catch (e) {
            console.log(e, etout) ;
        }
        this.exchangeColumns = exchangeColumns
        return etout;
      })
    },
    handleDelete(record) {
      let type = this.form.areaOrderType;
      if(type =='1'){
        //普通积分订单
        deleteMemberOrder({ id: record.id }).then(result => {
          this.$message.success('操作成功')
          this.$refs.table.refresh(true)
        })
      }else if(type =='2'){
        //兑换订单
          deleteExchangeOrder({ id: record.id }).then(result => {
            this.$message.success('操作成功')
            this.$refs.table.refresh(true)
          })

      }
    },
    //确认收货
    handleConfirm(record) {
      let type = this.form.areaOrderType;

      if(type =='1'){
        //普通积分订单
        this.deliver.loading = true;
        let param = Object.assign(this.deliver.form);
        drawCommonOrder(param).then(_result => {
          this.$message.success('操作成功')
          this.$refs.table.refresh(true)
          this.deliver.visible = false
          this.deliver.loading = false;
        }).catch(() => {
          this.deliver.loading = false;
        })
      }else if(type =='2'){
        //兑换订单
          this.deliver.loading = true;
          drawExchangeOrder(this.deliver.form).then(_result => {
            this.$message.success('操作成功')
            this.$refs.table.refresh(true)
            this.deliver.visible = false
            this.deliver.loading = false;
          }).catch(() => {
            this.deliver.loading = false;
          })

      }
    },
    handleDeliver() {
      this.$refs.form.validate((result) => {
        if (result) {
          this.deliver.loading = true;
          outExchangeOrder(this.deliver.form).then(_result => {
            this.$message.success('操作成功')
            this.$refs.table.refresh(true)
            this.deliver.visible = false
            this.deliver.loading = false;
          }).catch(() => {
            this.deliver.loading = false;
          })
        }
      })
    },
    initialDeliver(record) {
      this.deliver.loading = true
      let areaOrderType = this.form.areaOrderType;
      if(areaOrderType =='1'){
        getMemberOrder({id:record.id}).then(result => {
            this.initComfrimParams(result);
        });
      }else if(areaOrderType =='2'){
        queryCashDetail({id:record.id}).then(result => {
            this.initComfrimParams(result);
        });
      }

    },
    //积分&兑换积分初始化
    initialDeliver1(record) {
      let areaOrderType = this.form.areaOrderType;

      getMemberOrderAddress({ order: record.id }).then(result => {
        this.address = Object.assign({}, this.address, result)
      })
      if(areaOrderType =='1'){
        let prams = { current: 1, size: 1000,categoryName:'全部商品', status: 'online' };
        queryProductions(prams).then(p => {
          let productList = p;
          getMemberOrder({id:record.id}).then(result => {
            record.productions = this.cuProductList(productList,result);
            this.initUpdateProductParams(record);
          });
        })
      }else if(areaOrderType =='2'){
        let prams = { current: 1, size: 1000 };
        queryDuiHuanProductions(prams).then(p => {
          let productList = p;
          let orderId = record.id;//id:record.id
          queryCashDetail({id:orderId}).then(result => {
            record.productions = this.cuProductList(productList,result);
            this.initUpdateProductParams(record);
          });
        })
      }else{
        this.$message.error('订单类型错误')
      }

    },
    //更新补充产品列表
    initUpdateProductParams(record){
      this.deliver1.form = {
        id: record.id,
        productionList: record.productions,
        bussType: record.bussType,
        drawName: record.drawName,
        drawMobile: record.drawMobile,
        name: this.address.name,
        addressMobile: this.address.mobile,
        address: this.address.address,
        regionOneCode: this.address.regionOneCode,
        regionOneName: this.address.regionOneName,
        regionTwoCode: this.address.regionTwoCode,
        regionTwoName: this.address.regionTwoName,
        regionThreeCode: this.address.regionThreeCode,
        regionThreeName: this.address.regionThreeName,
      };
      if (this.deliver1.form.regionOneCode && this.deliver1.form.regionTwoCode && this.deliver1.form.regionThreeCode) {
        const province = this.region.find(item => item.adcode == this.deliver1.form.regionOneCode);
        if (province) {
          const city = province.districts.find(item => item.adcode == this.deliver1.form.regionTwoCode);
          if (city) {
            const area = city.districts.find(item => item.adcode == this.deliver1.form.regionThreeCode);
            this.deliver1.form.region = [province, city, area]
          }
        }
      }

      this.changeJiFen();
      this.deliver1.loading = false
      this.deliver1.visible = true
    },
    //计算全部商品列表组合显示
    cuProductList(result,record){
      let pl = result.records;//全部商品
      let production = record.productions;//本订单商品
      // debugger;
      for(let p in pl){
        let it = pl[p]
        it.quantity = 0;
        it.amount = 0;
        it.production = it.id;
        for(let i in production){
          let item = production[i];
          if(this.form.areaOrderType=='1'){
            //积分订单
            if(item.production==it.id){
              it.isDelete = true;
              break;
            }
          }else if(this.form.areaOrderType=='2'){
            //兑换订单
            if(item.id==it.id){
              it.isDelete = true;
              break;
            }
          }

        }
      }
      //过滤订单产品，并且合并商品列表
      for(let p in pl){
        let it = pl[p]
        if(!it.isDelete){
          //不存在就添加到产品列表中
          production.push(it);
        }
      }
      return production;
    },
    //积分-保存
    handleJiFenDeliver() {
        this.$refs.form1.validate((result) => {
          if (result) {
            this.deliver1.loading = true;
            if (this.deliver1.form.region && this.deliver1.form.region.length > 0) {
              this.deliver1.form.regionOneCode = this.deliver1.form.region[0].adcode;
              this.deliver1.form.regionOneName = this.deliver1.form.region[0].name;
              this.deliver1.form.regionTwoCode = this.deliver1.form.region[1].adcode;
              this.deliver1.form.regionTwoName = this.deliver1.form.region[1].name;
              this.deliver1.form.regionThreeCode = this.deliver1.form.region[2].adcode;
              this.deliver1.form.regionThreeName = this.deliver1.form.region[2].name;
            }

            const parameter = JSON.parse(JSON.stringify(this.deliver1.form));

            delete parameter.region;
            let ot = this.form.areaOrderType;

            if(ot =='1'){
                  putOrderProduction(parameter).then(_result => {
                    this.$message.success('操作成功')
                    this.$refs.table.refresh(true)
                    this.deliver1.visible = false
                    this.deliver1.loading = false;
                  }).catch(() => {
                    this.deliver1.loading = false;
                  })
            }else if(ot=='2'){
                  let proList = parameter.productionList;
                  let productionList = [];
                  for(let item of proList){
                        let p ={};
                        p.production = item.id;
                        p.quantity = item.quantity;
                        if(p.quantity>0){
                          productionList.push(p);
                        }
                  }
                  parameter.productionList = productionList;
                  debugger;
                  putExchangeProduction(parameter).then(_result => {
                    this.$message.success('操作成功')
                    this.$refs.table.refresh(true)
                    this.deliver1.visible = false
                    this.deliver1.loading = false;
                  }).catch(() => {
                    this.deliver1.loading = false;
                  })
            }


          }
        })
      },
    //积分更改产品显示价格
    changeJiFen(){
      let count = 0;
      if (this.deliver1.form.productionList) {
        for (const item of this.deliver1.form.productionList) {
          count += item.quantity;
        }
      }
      let price = 0;
      if (this.deliver1.form.productionList) {
        for (const item of this.deliver1.form.productionList) {
          item.price = item.price ? item.price : 0;
          price += item.quantity * item.price;
        }
      }
      this.deliver1.totalPrice = price;
      this.deliver1.totalCount = count;
    },
    //更新补充产品列表
    initComfrimParams(record){
      this.deliver.form = {
        id: record.id,
        productionList: record.productions,
        bussType: record.bussType,
        drawName: record.drawName,
        drawMobile: record.drawMobile,
      };


      this.changeJiFen();
      this.deliver.loading = false
      this.deliver.visible = true
    },
    showChuHuo(record){
      let merchantMember = record.merchantMember;
      let areaName = "";

      // 1）用户支付给商户，商户再支付给公司，卖方显示商户所属分公司名称；（有merchantMember）取merchantAreaName;
      // 2）用户/备选/商户下单直接支付给公司的，卖方显示自己所属分公司名称；（没有merchantMember）取memberAreaName;
      if(merchantMember){
        areaName = record.merchantAreaName;
      }else{
        areaName = record.memberAreaName;
      }
      debugger;

      this.printTime =moment().format(formatType);
      // debugger;
      let status = record.bussType;
      if(status == "draw"){
        //自提
        let addressUserMobile = record.drawMobile;
        let tempString = addressUserMobile.toString().substring(0,8)+"***";
        record.showAddressUserMobile =tempString;
        this.deliver4.visible=true;

        this.deliver4.form = record;
        this.deliver4.form.showCreateTime = this.getCurrentTime(record.createTime);
        this.deliver4.form.areaNameMai = areaName;

      }
      // else {
      //   // 物流
      //   this.deliver3.form = record;
      //   let address = this.sendInfoDesc(record);
      //   let mobile = record.address.mobile;
      //   let name = record.address.name;
      //   let logisticsProvider =record.address.logisticsProvider;
      //   let logisticsNo = record.address.logisticsNo;
      //
      //   this.deliver3.form.name = name;
      //   this.deliver3.form.addressDesc = address[1];
      //   this.deliver3.form.mobile = mobile;
      //   this.deliver3.form.logisticsProvider = logisticsProvider;
      //   this.deliver3.form.logisticsNo = logisticsNo;
      //
      //   this.deliver3.visible=true;
      // }

    },
    getCurrentTime(createTime) {
      const now = new Date(createTime);
      const hours = now.getHours();
      const minutes = now.getMinutes();
      const seconds = now.getSeconds();

      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    },
  }
}
</script>
<style lang="less" scoped>
/deep/.total {
  font-size: 20px;
}
/deep/.ant-page-header{
  display: none;
}


/deep/.diyTableStyle {
  font-weight: bold;
  font-family: "黑体", "微软雅黑", "宋体", serif;
  color: #000000;
}

/deep/.diyTableStyle .ant-descriptions-row {
  border:1px solid #000000;
  font-family: "黑体", "微软雅黑", "宋体", serif;
  color: #000000;
  font-weight: bold;
}

/deep/.diyTableStyle .ant-descriptions-item-content{
  border-right: 1px solid #000000;
  padding: 10px 5px;
  font-weight: bold;
  font-family: "黑体", "微软雅黑", "宋体", serif;
  color: #000000;
}
/deep/.diyTableStyle .ant-descriptions-item-label{
  padding: 10px 5px;
  font-weight: bold;
  font-family: "黑体", "微软雅黑", "宋体", serif;
  color: #000000;
  background-color: #ffffff;
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  border-right: 1px solid #000000;
}


/deep/.diyTableStyle_no_Border {
  font-weight: bold;
  font-family: "黑体", "微软雅黑", "宋体", serif;
  color: #000000;
}

/deep/.diyTableStyle_no_Border .ant-descriptions-row {
  font-family: "黑体", "微软雅黑", "宋体", serif;
  color: #000000;
  font-weight: bold;
}

/deep/.diyTableStyle_no_Border .ant-descriptions-item-content{
  font-weight: bold;
  font-family: "黑体", "微软雅黑", "宋体", serif;
  color: #000000;
}
/deep/.diyTableStyle_no_Border .ant-descriptions-item-label{
  font-weight: bold;
  font-family: "黑体", "微软雅黑", "宋体", serif;
  color: #000000;
  background-color: #ffffff;
}
</style>

