<template>
  <page-header-wrapper style="margin: unset;">
    <a-form-model ref='form' :model='form' layout='inline'>
      <a-form-model-item prop='title'>
        <a-select v-model="form.meetId" placeholder="活动时间" style="width:300px">
          <a-select-option :value="item.id" v-for="(item, index) in actList" :key="index">
            {{item.startDate}}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop='memberMobile'>
        <a-input v-model='form.memberMobile' style="width: 300px" placeholder="手机号码" />
      </a-form-model-item>
      <a-form-model-item prop='start'>
        <a-range-picker
          v-model="dateArr"
          :format="[formatType, formatType]"
          :show-time="{
            hideDisabledOptions: true,
          }"
          :disabled-date="disabledDate"
          @change="dateChange"
        />
      </a-form-model-item>
      <a-form-model-item>
        <a-button type='primary' @click='handleSubmit'>
          查询
        </a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click='handleReset'>
          重置
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <div>
      <div class='table-operator' style="margin-top: 20px">
        <a-button type='primary' icon='reload' @click='$refs.table.refresh(true)'>刷新</a-button>
        <a-button type='primary' icon='delete' @click='handleDeleteAll'>删除</a-button>
        <a-button type='primary' icon='table' @click='exportToExcel'>导出</a-button>
      </div>

      <s-table id="table" ref='table' rowKey='id' size='default' :columns='columns' :scroll='{ x: 1500}' :data='loadData' :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
        <span slot='startDate' slot-scope='text'>
            {{showMonthMin(text)}}
        </span>
        <span slot='action' slot-scope='text, record'>
          <template>
            <a-space>
              <a-popconfirm v-if="record.outStatus == 0" title='确定出库吗？' ok-text='确认' cancel-text='取消' @confirm='handleOut(record)'>
                <a href='#'>确定出库</a>
              </a-popconfirm>
              <a-popconfirm title='确定删除吗？' ok-text='确认' cancel-text='取消' @confirm='handleDelete(record)'>
                <a href='#'>删除</a>
              </a-popconfirm>
            </a-space>
          </template>
        </span>
      </s-table>
    </div>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis, ChartCard } from '@/components'
import {
  queryActivityOrder, deleteActivityOrder, outActivityOrder,
  queryActivityOrder499, outActivityOrder499

} from '@/api/member-order'
import FileSaver from 'file-saver'
import XLSX from 'xlsx'
import moment from 'moment'
import {deleteActivityOrder499, queryActivity499} from "@/api/activity";


const columns = [
  {
    width: 150,
    title: '订单号',
    dataIndex: 'id',
    scopedSlots: { customRender: 'id' }
  },
  {
    width: 150,
    title: '活动时间',
    dataIndex: 'startDate',
    scopedSlots: { customRender: 'startDate' }
  },
  {
    width: 150,
    title: '关联商品',
    dataIndex: 'depositoryProductionName',
    scopedSlots: { customRender: 'depositoryProductionName' }
  },
  {
    width: 150,
    title: '购买人',
    dataIndex: 'memberName',
    scopedSlots: { customRender: 'memberName' }
  },
  {
    width: 150,
    title: '购买人手机号',
    dataIndex: 'memberMobile',
    scopedSlots: { customRender: 'memberMobile' }
  },
  {
    width: 150,
    title: '咨询人',
    dataIndex: 'spreaderName',
    scopedSlots: { customRender: 'spreaderName' }
  },
  {
    width: 150,
    title: '下单时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
  {
    width: 150,
    title: '出库时间',
    dataIndex: 'outTime',
    scopedSlots: { customRender: 'outTime' }
  },
  {
    width: 150,
    title: '操作',
    fixed: 'right',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

const exportColums = [
  {
    width: 150,
    title: '订单号',
    dataIndex: 'id',
    scopedSlots: { customRender: 'id' }
  },
  {
    width: 150,
    title: '活动时间',
    dataIndex: 'startDate',
    scopedSlots: { customRender: 'startDate' }
  },
  {
    width: 150,
    title: '关联商品',
    dataIndex: 'depositoryProductionName',
    scopedSlots: { customRender: 'depositoryProductionName' }
  },
  {
    width: 150,
    title: '购买人',
    dataIndex: 'memberName',
    scopedSlots: { customRender: 'memberName' }
  },
  {
    width: 150,
    title: '购买人手机号',
    dataIndex: 'memberMobile',
    scopedSlots: { customRender: 'memberMobile' }
  },
  {
    width: 150,
    title: '咨询人',
    dataIndex: 'spreaderName',
    scopedSlots: { customRender: 'spreaderName' }
  },
  {
    width: 150,
    title: '下单时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
  {
    width: 150,
    title: '出库时间',
    dataIndex: 'outTime',
    scopedSlots: { customRender: 'outTime' }
  }
]

const formatType = 'YYYY-MM-DD HH:mm';
const baseStart = moment().startOf('month').format(formatType);
// const baseEnd = moment().format(formatType);

const baseEnd = moment().startOf('day').format('YYYY-MM-DD')+ ' 23:59';
export default {
  name: 'InsideStorehouseActivityList',
  components: {
    STable, Ellipsis, ChartCard
  },
  data() {
    return {
      actList:[],//会议列表
      dashboard: {},
      selectedRowKeys: [],
      // 筛选表单
      formatType,
      dateArr: [baseStart, baseEnd],
      form: {
        start: baseStart,
        end: baseEnd,
      },
      loading: true,
      total: 0,
      // 表头
      columns,
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        let params = Object.assign(parameter, this.form);
        debugger;
        return queryActivityOrder499(params)
          .then(datum => {
            this.total = datum.total;
            this.$emit("setActvityTitle", this.total);
            return datum
          })
      },
    }
  },
  created() {
    this.getActiveList();
  },
  methods: {
    showMonthMin(_date){
      if(_date){
        const date = new Date(_date);
        const formattedDate = moment(date).format('YYYY-MM-DD HH:mm');
        return formattedDate;
      }else{
        return  "";
      }
    },
    moment,
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    handleDeleteAll() {
      const requestList = [];
      this.selectedRowKeys.forEach(item => {     
         requestList.push(deleteActivityOrder({ id: item }))
      });
      if (requestList.length == 0) {
        this.$message.warning('至少选择一个订单');
        return;
      }
      Promise.all(requestList).then(result => {
        this.$message.success('操作成功');
        this.handleInitial();
      })

    },
    dateChange() {
      this.form.start =  this.dateArr[0] ? moment(this.dateArr[0]).format(this.formatType) : "";
      this.form.end =  this.dateArr[1] ? moment(this.dateArr[1]).format(this.formatType) : "";
    },
    handleReset() {
      this.dateArr = [baseStart, baseEnd];
      this.form.start = baseStart;
      this.form.end = baseEnd;
      this.$refs.form.resetFields()
      this.$refs.table.refresh(true)
    },
    handleSubmit() {
      this.$refs.table.refresh(true)
    },
    exportToExcel () {
      this.columns = exportColums
      this.$nextTick(() => {
        let et = XLSX.utils.table_to_book(document.querySelector('#table'),{ raw: true }); //此处传入table的DOM节点
        let etout = XLSX.write(et, {
          bookType: 'xlsx',
          bookSST: true,
          type: 'array'
        });
        try {
            FileSaver.saveAs(new Blob([etout], {
                type: 'application/octet-stream'
            }), '普通订单.xlsx');   //trade-publish.xlsx 为导出的文件名
        } catch (e) {
            console.log(e, etout) ;
        }
        this.columns = columns
        return etout;
      })
    },
    handleDelete(record) {
      deleteActivityOrder499({ id: record.id }).then(result => {
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      })
    },
    handleOut(record) {
      outActivityOrder499({ id: record.id }).then(result => {
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      })
    },
    //获取会议列表
    getActiveList(){
      let params = Object.assign({pageSize: 99,status:'2'},this.form);
      queryActivity499(params).then(result => {
        this.actList = result.records;
      })
    },
  }
}
</script>
<style lang="less" scoped>
/deep/.total {
  font-size: 20px;
}
/deep/.ant-page-header{
  display: none;
}
</style>

